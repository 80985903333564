import React from 'react';
import Link from './Link';
import Image from './Image';

export default function ThreeCTABoxes({ data }) {
  return (
    <section className="pad-b--sm">
      <div className="container">
        <div className="grid md:grid-cols-3 gap-6">
          {data.map((box, i) => (
            <Link to={box.ctaBoxButton?.url} key={box.heading + i} className={`cursor-default flex flex-col text-white ${i === 0 && 'bg-accent'} ${i === 1 && 'bg-teal'} ${i === 2 && 'bg-l-blue'}`}>
              <div className="py-12 px-8 flex-auto">
                <Image data={box.icon} style={{ maxWidth: '47px' }} />
                <h4 className="text-40px font-semibold leading-snug mt-8 mb-4" dangerouslySetInnerHTML={{ __html: box.heading }} />
                <p className="font-medium" style={{ maxWidth: '250px' }} dangerouslySetInnerHTML={{ __html: box.content }} />
              </div>
              <button className="button button--black button--waves w-full text-left cursor-pointer" dangerouslySetInnerHTML={{ __html: box.ctaBoxButton?.title }} />
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}
