import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import submitForm from '../utils/submitForm';

export default ({ activeButton, isSent, setIsSent }) => {
  const { register, handleSubmit, errors, setValue } = useForm();

  const onSubmit = async (fields) => {
    const { status } = await submitForm({
      formId: '82d44f9f-2938-4606-8c16-62b75b1613fc',
      fields,
    });
    if (status === 200) {
      setIsSent(true);
      return;
    }
  };

  useEffect(() => {
    setValue('department', activeButton);
  }, [activeButton]);

  return (
    <form className={`mx-auto ${isSent ? 'pointer-events-none select-none' : ''}`} style={{ maxWidth: '444px' }} onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" value={activeButton} ref={register({ name: 'department' })} />

      <div className="grid gap-4">
        <label className={`border-2 block ${errors.email && 'is-invalid'}`}>
          <input type="email" aria-label="Email" name="email" ref={register({ required: true })} placeholder="Your work email" />
        </label>
        <label className={`border-2 block ${errors.phone && 'is-invalid'}`}>
          <input type="text" aria-label="Phone" name="phone" ref={register({ required: true })} placeholder="Your number" />
        </label>
        <label className={`border-2 block ${errors.name && 'is-invalid'}`}>
          <input type="text" aria-label="Name" name="name" ref={register({ required: true })} placeholder="Your name" />
        </label>
        <label className={`border-2 block ${errors.company && 'is-invalid'}`}>
          <input type="text" aria-label="Company" name="company" ref={register} placeholder="Your company" />
        </label>
        <label className={`border-2 block ${errors.message && 'is-invalid'}`}>
          <textarea aria-label="Message" name="message" ref={register} placeholder="What can we show you?" />
        </label>
      </div>
      <label className="text-14px flex items-center justify-center py-6">
        <input type="checkbox" name="accept" ref={register({ required: true })} className="mr-3" />
        <div className={`${errors.accept && 'is-invalid text-red'}`}>
          I accept Programmai’s{' '}
          <a href="/privacy-policy/" className="underline">
            Privacy Policy
          </a>
        </div>
      </label>
      <button type="submit" className="w-full text-left button button--black button--waves items-center" style={{ height: '60px' }}>
        Submit
      </button>
    </form>
  );
};
