import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Link from '../components/Link';
import ThreeCTABoxes from '../components/ThreeCTABoxes';
import styled from 'styled-components';
import tw from 'twin.macro';
import media from '../utils/media';
import SEO from '../components/SEO';
import Social from '../components/Social';
import ContactForm from '../components/ContactForm';

const Buttons = styled.div`
  ${tw`grid grid-cols-3 gap-3 mx-auto`}
  max-width:484px;
  ${media.greaterThan('sm')`
    height:52px;
    max-height:52px;
    overflow:hidden;
    &.is-sent {
      max-height:0;
      transition:max-height .3s ease-in-out;
    }
  `}
`;

export default React.memo((props) => {
  const { seo } = props.data.wpPage;
  const { contactHero, form, office, ctaBoxes } = props.data.wpPage.contactPage;
  const [activeButton, setActiveButton] = useState(form.buttons[0].button);

  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (isSent === true && window.scrollY > 50) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [isSent]);

  return (
    <Layout>
      <SEO seo={seo} />

      <section className="pad-t pad-b--sm text-center">
        <div className="container">
          <header className="mb-8">
            <h1 className="text-58px mb-2 overflow-visible" style={{ letterSpacing: '-0.03em', lineHeight: '1.3' }}>
              {!isSent ? contactHero.heading : form.confirmation.heading}
            </h1>
            <p className="text-20px mb-6 font-medium" dangerouslySetInnerHTML={{ __html: !isSent ? form.formDescription : form.confirmation.content }} />

            <div className={`transition-all duration-300 ${!isSent ? 'md:mb-10 mb-8' : ''}`}>
              <Buttons className={`transition-opacity duration-300 ease-in-out ${isSent ? 'is-sent' : ''}`}>
                {form.buttons.map(({ button }) => (
                  <button key={`btn-${button}`} onClick={() => setActiveButton(button)} className={`h-full py-2 px-3 font-medium text-white transition duration-100 select-none ${button === activeButton ? 'bg-purple' : 'bg-black'}`}>
                    {button}
                  </button>
                ))}
              </Buttons>
            </div>
          </header>

          <div className={`contact-form transition-opacity duration-500 ease-in-out ${isSent ? 'opacity-25' : ''}`}>
            <ContactForm activeButton={activeButton} isSent={isSent} setIsSent={setIsSent} />
          </div>
        </div>
      </section>

      <section className="pad-b--sm office text-center">
        <div className="container">
          <div className="max-w-sm mx-auto">
            <h3 className="font-bold text-32px mb-3">{office.heading}</h3>
            <address className="not-italic mb-4" dangerouslySetInnerHTML={{ __html: office.address }} />
            <Link className="text-link text-link--black mb-6" to={office.button.url} target={office.button.target}>
              {office.button.title}
            </Link>
            <Social />
          </div>
        </div>
      </section>

      <ThreeCTABoxes data={ctaBoxes} />
    </Layout>
  );
});

export const query = graphql`
  query Contact {
    wpPage(slug: { eq: "contact" }) {
      id
      title
      slug
      ...SEO
      contactPage {
        contactHero {
          heading
        }
        form {
          buttons {
            button
          }
          confirmation {
            content
            heading
          }
          formDescription
        }

        office {
          address
          button {
            target
            title
            url
          }
          heading
        }
        ctaBoxes {
          content
          ctaBoxButton {
            target
            title
            url
          }
          heading
          icon {
            ...Image
          }
        }
      }
    }
  }
`;
